<template>
  <div class="animated fadeIn">
    <Form :model="formItem" :label-width="150">
      <b-Row class="mt-4">
        <b-col cols="12" md="12" lg="6" class-name="custom-col" class="mt-2">
          <b-Card>
            <p slot="title" v-if="this.mode !== 'edit'">{{$t('message.create')}}</p>
            <p slot="title" v-else>{{$t('message.edit')}}</p>
            <div class="example-avatar">
              <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active"></div>
              <div class="avatar-upload" v-show="!edit">
                <div class="text-center p-2">
                  <label for="avatar">
                    <img :src="files.length ? files[0].url : 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'" class="rounded" />
                  </label>
                </div>
                <div class="text-center p-2">
                  <file-upload
                    extensions="gif,jpg,jpeg,png,webp"
                    accept="image/png, image/gif, image/jpeg, image/webp"
                    name="avatar"
                    class="btn btn-primary"
                    :custom-action="customUpload"
                    :drop="!edit"
                    v-model="files"
                    @input-filter="inputFilter"
                    @input-file="inputFile"
                    ref="upload"
                  >Upload Image</file-upload>
                </div>
              </div>
              <div class="avatar-edit" v-show="files.length && edit">
                <div class="avatar-edit-image" v-if="files.length">
                  <img ref="editImage" :src="files[0].url" />
                </div>
                <div class="text-center p-4">
                  <button type="button" class="btn btn-secondary" @click.prevent="$refs.upload.clear">Cancel</button>
                  <button type="button" class="btn btn-primary" @click.prevent="editSave">Save</button>
                </div>
              </div>
            </div>
            <FormItem :label="$t('message.userName')">
              <Input v-model="formItem.user.userName" placeholder="Enter Username"></Input>
            </FormItem>
            <FormItem :label="$t('message.password')">
              <Input type="password" v-model="formItem.user.passWord" placeholder="Enter password"></Input>
            </FormItem>
            <FormItem :label="$t('message.confirmPassword')">
              <Input type="password" v-model="formItem.confirmPassword" placeholder="Enter password again"></Input>
            </FormItem>
            <FormItem :label="$t('message.language')">
              <Select v-model="formItem.user.config.language">
                <Option value="th">Thai</Option>
                <Option value="en">English</Option>
              </Select>
            </FormItem>
            <FormItem :label="$t('message.role')">
              <Select v-model="formItem.user.role">
                <Option value>Select</Option>
                <Option value="admin">Admin</Option>
                <Option value="dealer">Dealer</Option>
                <Option value="owner">Owner</Option>
                <Option value="callcenter">Callcenter</Option>
              </Select>
            </FormItem>
            <FormItem :label="$t('message.type')">
              <Select v-model="formItem.user.type">
                <Option value>Select</Option>
                <Option value="admin">Admin</Option>
                <Option value="wonderwash">Wonderwash</Option>
                <Option value="oem">OEM</Option>
                <Option value="callcenter">Callcenter</Option>
                <Option value="other">Other</Option>
              </Select>
            </FormItem>
          </b-Card>
        </b-col>
        <b-col cols="12" md="12" lg="6" class-name="custom-col" class="mt-2">
          <b-Card>
            <p slot="title">รายละเอียด</p>
            <FormItem :label="$t('message.nameTH')">
              <b-Row>
                <b-col cols="12" md="6" lg="6">
                  <Input v-model="formItem.info.firstNameTh" placeholder="ชื่อ" />
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <Input v-model="formItem.info.lastNameTh" placeholder="นามสกุล" />
                </b-col>
              </b-Row>
            </FormItem>
            <FormItem :label="$t('message.nameEN')">
              <b-Row>
                <b-col cols="12" md="6" lg="6">
                  <Input v-model="formItem.info.firstNameEn" placeholder="First name" />
                </b-col>
                <b-col scols="12" md="6" lg="6">
                  <Input v-model="formItem.info.lastNameEn" placeholder="Last name" />
                </b-col>
              </b-Row>
            </FormItem>
            <FormItem :label="$t('message.refNumber')">
              <b-Row>
                <b-col cols="12" md="12" lg="12">
                  <Input v-model="formItem.info.phoneNumber" />
                </b-col>
              </b-Row>
            </FormItem>
            <FormItem :label="$t('message.smsNumber') + 1">
              <b-Row>
                <b-col cols="12" md="12" lg="12">
                  <Input v-model="formItem.info.smsNumber1" />
                </b-col>
              </b-Row>
            </FormItem>
            <FormItem :label="$t('message.smsNumber') + 2">
              <b-Row>
                <b-col cols="12" md="12" lg="12">
                  <Input v-model="formItem.info.smsNumber2" />
                </b-col>
              </b-Row>
            </FormItem>
            <FormItem :label="$t('message.email')">
              <b-Row>
                <b-col cols="12" md="12" lg="12">
                  <Input v-model="formItem.info.email"></Input>
                </b-col>
              </b-Row>
            </FormItem>
            <FormItem :label="$t('message.address')">
              <b-Row>
                <b-col span="21">
                  <Input v-model="formItem.info.addressName" type="textarea" :autosize="{minRows: 2,maxRows: 5}" :placeholder="$t('message.address')"></Input>
                </b-col>
              </b-Row>

              <b-Row>
                <b-col cols="12" md="6" lg="6">
                  <div class="form-group">
                    <label>แขวง/ตำบล</label>
                    <thai-address-input type="subdistrict" v-model="formItem.info.subdistrict" input-class="form-control" @selected="onSelected"></thai-address-input>
                  </div>
                </b-col>
                
                <b-col cols="12" md="6" lg="6">
                  <div class="form-group">
                    <label>เขต/อำเภอ</label>
                    <thai-address-input type="district" v-model="formItem.info.district" input-class="form-control" @selected="onSelected"></thai-address-input>
                  </div>
                </b-col>
              </b-Row>

              <b-Row>
                <b-col cols="12" md="6" lg="6">
                  <div class="form-group">
                    <label>จังหวัด</label>
                    <thai-address-input type="province" v-model="formItem.info.province" input-class="form-control" @selected="onSelected"></thai-address-input>
                  </div>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <div class="form-group">
                    <label>รหัสไปรษณีย์</label>
                    <thai-address-input type="postalCode" v-model="formItem.info.zipcode" input-class="form-control" @selected="onSelected"></thai-address-input>
                  </div>
                </b-col>
              </b-Row>
            </FormItem>
          </b-Card>
        </b-col>
      </b-Row>
      <b-Row>
        <b-Col class-name="custom-col" class="mt-2">
          <b-Card style="text-align:right">
              <Button type="primary" size="large" @click="save(formItem)">{{ $t('message.save') }}</Button>
              <Button size="large" style="margin-left: 8px">{{ $t('message.cancel') }}</Button>
          </b-Card>
        </b-Col>
      </b-Row>
    </Form>
  </div>
</template>
<script>
import { DOMAIN } from '../../app.config'
import { buildFormData } from '../../lib/buildFormData'

import 'cropperjs/dist/cropper.css'
import Cropper from 'cropperjs'
import FileUpload from 'vue-upload-component'
import moment from "moment";
export default {
  components: {
    FileUpload
  },
  data() {
    return {
      id: this.$route.params.userId,
      mode: this.$route.params.mode,
      formItem: {
        user: {
          config: {
            language: 'th'
          }
        },
        info: {
          subdistrict: '',
          district: '',
          province: '',
          zipcode: ''
        }
      },
      formData: new FormData(),
      files: [],
      edit: false,
      cropper: false
    }
  },
  methods: {
    onSelected(address) {
      this.formItem.info.subdistrict = address.subdistrict
      this.formItem.info.district = address.district
      this.formItem.info.province = address.province
      this.formItem.info.zipcode = address.postalCode
    },
    save(formItem) {
      console.log(formItem)

      var localStorageUser =  JSON.parse(window.localStorage.getItem("users") )
      var datetimeRequest = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

      this.formItem.userName = localStorageUser.userName
      this.formItem.userRole = localStorageUser.role
      this.formItem.userType = localStorageUser.type
      this.formItem.userConfiglanguage = localStorageUser.config.language
      this.formItem.datetimeRequest = datetimeRequest

      if ((!formItem.user.userName || !formItem.user.passWord) && !formItem.user.userId) {
        this.$toast.error({
          title: 'ERROR',
          message: 'Username or Password is empty'
        })
        return console.log('Username or Password is empty')
      }

      if (formItem.confirmPassword != formItem.user.passWord) {
        this.$toast.error({
          title: 'ERROR',
          message: 'Password not match'
        })
        return console.log('Password not match')
      }

      buildFormData(this.formData, this.formItem)

      console.log(this.formData)

      this.axios
        .post('/user', this.formData)
        .then(res => {
          if (res.data.success) {
            this.$toast.success({
              title: 'SUCCESS',
              message: 'User has been created'
            })
            this.$router.push('/user/list')
          } else {
            this.$toast.error({
              title: 'ERROR',
              message: `[${res.data.error}] : ${res.data.errorText}`
            })
          }
        })
        .catch(err => {
          console.log(err)
          console.log('error @new user')
          this.$toast.error({
            title: 'ERROR',
            message: 'เกิดข้อผิดพลาด'
          })
          if (err.response.status === 401) {
            // webServices.tokenExpire()
            window.localStorage.clear()
            window.location.reload()
          }
        })
    },
    editSave() {
      this.edit = false
      let oldFile = this.files[0]
      let binStr = atob(this.cropper.getCroppedCanvas().toDataURL(oldFile.type).split(',')[1])
      let arr = new Uint8Array(binStr.length)
      for (let i = 0; i < binStr.length; i++) {
        arr[i] = binStr.charCodeAt(i)
      }
      let file = new File([arr], oldFile.name, { type: oldFile.type })
      this.$refs.upload.update(oldFile.id, {
        file,
        type: file.type,
        size: file.size,
        active: true
      })
    },
    alert(message) {
      alert(message)
    },
    inputFile(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        this.$nextTick(function () {
          this.edit = true
        })
      }
      if (!newFile && oldFile) {
        this.edit = false
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.alert('Your choice is not a picture')
          return prevent()
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
        }
      }
    },
    async customUpload(file, component) {
      this.formData.append('file', file.file)
    }
  },
  watch: {
    edit(value) {
      if (value) {
        this.$nextTick(function () {
          if (!this.$refs.editImage) {
            return
          }
          let cropper = new Cropper(this.$refs.editImage, {
            aspectRatio: 1 / 1,
            viewMode: 1
          })
          this.cropper = cropper
        })
      } else {
        if (this.cropper) {
          this.cropper.destroy()
          this.cropper = false
        }
      }
    }
  },
  mounted() {
    if (this.mode === 'edit') {
      this.axios.get('/user/' + this.id).then(res => {
        const info = res.data.user.info
        this.formItem = res.data.user
        this.formItem.info.subdistrict = info.subdistrict || ''
        this.formItem.info.district = info.district || ''
        this.formItem.info.province = info.province || ''
        this.formItem.info.zipcode = info.zipcode || ''

        var localStorageUser =  JSON.parse(window.localStorage.getItem("users") )
        var datetimeRequest = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

        this.formItem.userName = localStorageUser.userName
        this.formItem.userRole = localStorageUser.role
        this.formItem.userType = localStorageUser.type
        this.formItem.userConfiglanguage = localStorageUser.config.language
        this.formItem.datetimeRequest = datetimeRequest

        if (this.formItem.info.image) {
          this.files[0] = { url: DOMAIN + '/' + this.formItem.info.image }
        }
      })
    }
  }
}
</script>
<style lang="scss">
.example-avatar .avatar-upload .rounded {
  width: 200px;
  height: 200px;
}
.example-avatar .text-center .btn {
  margin: 0 0.5rem;
}
.example-avatar .avatar-edit-image {
  max-width: 100%;
}
.example-avatar .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}
.example-avatar .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}

.custom-col {
  padding: 10px;
}
</style>
